import classnames from 'classnames';
import { ConnectedProps, connect } from 'react-redux';
import { Button, ButtonGroup, Card, CardBody, CardImg, CardText, CardTitle } from 'reactstrap';
import resourcePng from '../../assets/images/placeholders/resource.png';
import { i18nCText } from '../../libs/i18n/I18n';
import { redirectTo } from '../../routes/helpers';
import { getCurrentUser } from '../../state/selectors/users';
import { Col, Container, Modal, ModalBody, ModalFooter, Row } from '../UI/Html';

import { ProjectOptions, ProjectStage, UserProject } from '@app/@types/redux/project';
import env from '@config/env';
import { ROLES } from '@libs/constants/constants';
import logger from '@libs/log';
import {
  fetchUserProject,
  resetUserEvaluation,
  resetUserProject,
  toggleExamplePopup,
  toggleInstructionPopup,
  toggleNoteSidePane,
  toggleResourcesPopup,
  toggleVideoPopup,
} from '@state/redux/slices/project.slice';
import { useEffect } from 'react';
import { AppDispatch, RootState } from '../../state/redux/store';
import { translate } from '../../state/utils/helper';
import NoteSidePane from '../Users/Notes/NoteSidePane';
import './ProjectHeader.scss';

const mapStateToProps = (state: RootState) => ({
  currentUser: getCurrentUser(state),
  videoPopup: state.projects.popupFlag.videoPopup,
  resourcesPopup: state.projects.popupFlag.resourcesPopup,
  instructionPopup: state.projects.popupFlag.instructionPopup,
  examplePopup: state.projects.popupFlag.examplePopup,
  noteSidePane: state.projects.popupFlag.noteSidePane,
  note: state.projects.currentProject.userProject.attributes.userNote,
  projectId: state.projects.currentProject.project.id,
  currentWorkspace: state.workspaces.currentWorkspace,
  userWorkspace: state.workspaces.userWorkspace,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  toggleVideoPopup: (flag: boolean) => {
    dispatch(toggleVideoPopup(flag));
  },
  toggleInstructionPopup: (flag: boolean) => {
    dispatch(toggleInstructionPopup(flag));
  },
  toggleExamplePopup: (flag: boolean) => {
    dispatch(toggleExamplePopup(flag));
  },
  toggleResourcesPopup: (flag: boolean) => {
    dispatch(toggleResourcesPopup(flag));
  },
  toggleNoteSidePane: (flag: boolean) => {
    dispatch(toggleNoteSidePane(flag));
  },
  fetchUserProject: (id: string) => dispatch(fetchUserProject({ id })),
  resetQuiz: (id: string) => dispatch(resetUserEvaluation({ id })),
  resetProject: (workspaceId: string, projectId: string) =>
    dispatch(resetUserProject({ workspaceId, projectId })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type ProjectHeaderProps = PropsFromRedux & {
  pageType?: string;
  title?: string;
  subtitle?: string;
  pageId?: string;
  evaluationId?: string;
  resetQuizHidden?: boolean;
  userProject?: UserProject;
  projectOptions?: ProjectOptions;
  workspaceId?: string;
  currentStage?: ProjectStage;
  toggleSidebar?: () => void;
};

const ProjectHeader: React.FC<ProjectHeaderProps> = (props) => {
  const {
    title,
    subtitle,
    toggleSidebar,
    noteSidePane,
    toggleNoteSidePane,
    note,
    projectOptions,
    evaluationId,
    currentUser: { id: userId },
    resetQuiz,
    resetQuizHidden,
    resetProject,
    currentWorkspace,
    workspaceId,
    projectId,
    fetchUserProject,
    pageType,
    toggleResourcesPopup,
    resourcesPopup,
    toggleVideoPopup,
    toggleInstructionPopup,
    toggleExamplePopup,
    userWorkspace,
  } = props;

  useEffect(() => {
    const navbar = document.getElementById('rachit-navbar');
    if (navbar) {
      navbar.classList.add('d-none', 'd-md-block');
    }
    if (pageType !== 'evaluationPage') {
      fetchUserProject(projectId).catch(() => {
        logger.error('Error fetching user project');
      });
    }
  }, [pageType, projectId]);

  const hasCurrentWorkspaceResources =
    currentWorkspace.attributes.options.resources &&
    currentWorkspace.attributes.options.resources.length > 0;
  const hasProjectOptionsResources = projectOptions && projectOptions.resources.length > 0;

  const showModal = () => {
    const resources = hasCurrentWorkspaceResources ?? hasProjectOptionsResources;
    let content = <></>;
    let toggleFunction = toggleResourcesPopup;
    if (resources && resourcesPopup) {
      toggleFunction = toggleResourcesPopup;
      content = (
        <>
          <h2 className="my-4">Challenge Resources</h2>
          <Row>
            {hasProjectOptionsResources ? (
              projectOptions.resources.map((resource) => {
                return (
                  <Col key={resource.url} className="" sm={12} md={3}>
                    <Card>
                      <CardImg alt="..." src={resourcePng} top />
                      <CardBody>
                        <CardTitle className="font-weight-bold">
                          {i18nCText(resource.name)}
                        </CardTitle>
                        <CardText>
                          <Button
                            color="link"
                            onClick={(e: React.MouseEvent) => {
                              e.preventDefault();
                              window.open(resource.url);
                            }}
                          >
                            View
                          </Button>
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })
            ) : (
              <Col className="text-center" sm={12} md={12}>
                No resources found
              </Col>
            )}
          </Row>
          <h2 className="my-4">Workspace Resources</h2>
          <Row>
            {hasCurrentWorkspaceResources ? (
              currentWorkspace.attributes.options.resources?.map((resource) => {
                return (
                  <Col key={resource.url} className="" sm={12} md={3}>
                    <Card>
                      <CardImg alt="..." src={resourcePng} top />
                      <CardBody>
                        <CardTitle className="font-weight-bold">
                          {i18nCText(resource.name)}
                        </CardTitle>
                        <CardText>
                          <Button
                            color="link"
                            onClick={(e: React.MouseEvent) => {
                              e.preventDefault();
                              window.open(resource.url);
                            }}
                          >
                            Download
                          </Button>
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })
            ) : (
              <Col className="text-center" sm={12} md={12}>
                No resources found
              </Col>
            )}
          </Row>
        </>
      );
    }

    return (
      <Modal
        className="modal-lg"
        isOpen={resourcesPopup}
        toggle={() => {
          toggleFunction(false);
        }}
      >
        <Row>
          <Col>
            <div className="d-flex flex-column text-center">
              <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                {resources ? (
                  <Button
                    color="secondary"
                    className={classnames({
                      active: resourcesPopup,
                      projectHeaderModalBtn: true,
                    })}
                    onClick={() => {
                      openModalAndCloseOthers('resources');
                    }}
                  >
                    <i className="fas fa-bookmark" /> {translate('takeProject.resources')}
                  </Button>
                ) : (
                  ''
                )}
              </ButtonGroup>
            </div>
          </Col>
        </Row>
        <ModalBody className="p-0">
          <Container>{content}</Container>
        </ModalBody>
        <ModalFooter>
          <Button
            className="text-danger ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              toggleFunction(false);
            }}
          >
            {translate('buttonLabels.close')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  function openModalAndCloseOthers(res: string) {
    toggleExamplePopup(false);
    toggleVideoPopup(false);
    toggleInstructionPopup(false);
    toggleResourcesPopup(false);
    if (res === 'video') {
      toggleVideoPopup(true);
    }
    if (res === 'instruction') {
      toggleInstructionPopup(true);
    }
    if (res === 'example') {
      toggleExamplePopup(true);
    }
    if (res === 'resources') {
      toggleResourcesPopup(true);
    }
  }

  const resources = hasCurrentWorkspaceResources ?? hasProjectOptionsResources;
  return (
    <>
      {resources && pageType !== 'evaluationPage' ? showModal() : ''}
      <NoteSidePane
        isOpen={noteSidePane}
        onRequestClose={() => {
          toggleNoteSidePane(false);
        }}
        note={note}
      />
      <div className="header bg-info pb-4">
        <Container fluid>
          <div className="header-body">
            <div className="projectHeader__row py-4">
              <div className="projectHeader__col">
                <h4 className="projectHeader__title">{title}</h4>
                {subtitle && <h1 className="projectHeader__subtitle">{subtitle}</h1>}
              </div>
              <div className="projectHeader__col">
                {(
                  [
                    {
                      id: 'goBack',
                      icon: 'fas fa-home',
                      buttonName: translate('projectPreview.section.overview.label'),
                      onClick: () => {
                        redirectTo(
                          `/workspace/${
                            projectOptions?.creationOptions.workspaceId ?? ''
                          }/challenge/${projectId}/overview`
                        );
                      },
                      hidden:
                        pageType === 'evaluationPage' ||
                        Number(projectOptions?.creationOptions.creatorId) !== Number(userId),
                      classes: 'project-header-menu-button',
                    },
                    {
                      id: 'editChallenge',
                      icon: 'fas fa-pencil-alt',
                      buttonName: translate('takeProject.editChallenge'),
                      onClick: () => {
                        window.open(
                          `${env.CREATION_PLATFORM_URL}/workspace/${
                            projectOptions?.creationOptions.workspaceId ?? ''
                          }/project/${projectId}/edit`,
                          '_self'
                        );
                      },
                      hidden:
                        pageType === 'evaluationPage' ||
                        Number(projectOptions?.creationOptions.creatorId) !== Number(userId),
                      classes: 'project-header-menu-button',
                      color: 'danger',
                    },
                    {
                      id: 'resources',
                      icon: 'fas ',
                      buttonName: translate('takeProject.resources'),
                      onClick: (e: MouseEvent) => {
                        e.preventDefault();
                        if (resources) {
                          openModalAndCloseOthers('resources');
                        }
                      },
                      hidden: !resources,
                      classes: '',
                    },
                    {
                      id: 'resetQuiz',
                      icon: 'fas fa-repeat',
                      buttonName: translate('takeProject.resetQuiz'),
                      onClick: () => {
                        evaluationId &&
                          resetQuiz(evaluationId).catch(() => {
                            logger.error('Error resetting quiz');
                          });
                      },
                      hidden: pageType === 'projectPage' || resetQuizHidden,
                    },
                    {
                      id: 'resetChallenge',
                      icon: 'fas fa-repeat',
                      buttonName: translate('takeProject.resetChallenge'),
                      onClick: () => {
                        if (workspaceId)
                          resetProject(workspaceId, projectId).catch(() => {
                            logger.error('Error resetting project');
                          });
                      },
                      hidden:
                        pageType === 'evaluationPage' ||
                        !userWorkspace.attributes.userWorkspaceRoles.includes(ROLES.TESTER),
                      classes: '',
                    },
                    {
                      id: 'menu',
                      icon: 'fas fa-bars',
                      buttonName: translate('takeProject.menu'),
                      onClick: () => {
                        toggleSidebar && toggleSidebar();
                      },
                      hidden: pageType === 'evaluationPage',
                      classes: 'project-header-menu-button',
                    },
                  ] as {
                    id: string;
                    icon: string;
                    buttonName: string;
                    onClick: (e: MouseEvent) => void;
                    hidden: boolean;
                    classes: string;
                    color: string;
                  }[]
                ).map((item) => (
                  <Button
                    color={item.color}
                    className={`my-1 ${item.classes}`}
                    size="sm"
                    onClick={item.onClick}
                    hidden={item.hidden}
                    key={item.id}
                  >
                    <i className={`${item.icon} mr-0`} />
                    <span className="mx-2">{item.buttonName}</span>
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default connector(ProjectHeader);
